import React from 'react';
import WebMap from './WebMap';
import { GoogleApiWrapper } from 'google-maps-react';
import ReactGA from 'react-ga';

const GOOGLE_API_KEY = 'AIzaSyAlmOLYP6Ymi0yF3670LIbrDF7R - S3B7m4';

export class MapContainer extends React.Component {
  state = {
     resized: false,
  }


  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  handleResize = () =>
  {
    // Set state so re-render happens
    this.setState({ resized:true,});
  }

  render() {
    return (
      <WebMap google={this.props.google} />
    )
  }
}

export default GoogleApiWrapper({
  apiKey: (GOOGLE_API_KEY), libraries: ['places']
})(MapContainer)