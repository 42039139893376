import React, { Component } from 'react';
import { Button, Row, Col, Modal } from 'antd';
import MapMobilityDetails from './MapMobilityDetails';
import Label from '../Label';
import ReactGA from 'react-ga';

export class MapMobilityTab extends Component {
  state = {
    score: '',
    numReviews: 0,
    accessibilityIcon: null,
    mapScores: {},
    mapDetails: [],
    locationId: '',
    locationName: '',
    showReportWindow: false,
    reportType: '',
  }

  componentDidMount() {
    this.setState({ locationId: this.props.locationId, locationName: this.props.locationName, mapFeatures: this.props.mapFeatures, mapScores: this.props.mapScores, mapDetails: this.props.mapDetails, reportType: this.props.reportType, disability: this.props.disability, });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps || this.state.locationId !== prevState.locationId) {
      this.loadTab();
    }
  }

  loadTab = () => {
    let { disability, mapScores } = this.state;
    let score = '';
    switch (disability) {
      case 'Mobility':
        score = mapScores.mobilityRating;
        this.setState({ numReviews: mapScores.mobilityReviews, score: score, });
        break;
      case 'Hearing':
        score = mapScores.hearingRating;
        this.setState({ numReviews: mapScores.hearingReviews, score: score, });
        break;
      case 'Vision':
        score = mapScores.visionRating;
        this.setState({ numReviews: mapScores.visionReviews, score: score, });
        break;
      default:
    }
  };

  showReportModal = () => {
    ReactGA.event({ category: 'Button', action: 'Click', label: 'Open Reviews', });
    this.setState({ showReportWindow: true, });
  };

  closeReportModal = () => {
    this.setState({ showReportWindow: false, });
  };

  render() {
    const { reportType, disability, score, numReviews, locationId, locationName, showReportWindow, mapDetails } = this.state;
    let ReportTitle = <h3 style={{ marginTop: '4px', width: '95%', color: 'white' }}> {locationName + ' - ' + disability + ' Details'}</h3>;
    let ReportWindow = null;

    if(showReportWindow===true)
      ReportWindow = <Modal
        title={ReportTitle}
        visible={showReportWindow}
        onCancel={this.closeReportModal}
        width={600}
        footer={null}
        maskClosable={false}
        style={{top:56}}
      >
        <MapMobilityDetails locationId={locationId} disability={disability} mapDetails={mapDetails} reportType={reportType} />
        <Row>
          <Col span={24} style={{ marginTop: '16px' }}>
            <Button type='default' size='large' style={{ float: 'right', width: '116px' }} onClick={() => this.closeReportModal()}>Close</Button>
          </Col>
        </Row>
      </Modal>;
 
    return (
      <React.Fragment>
        <div onClick={() => this.showReportModal()} style={{ backgroundColor: '#0b5980', color: 'white', border: numReviews < 1 ? 'dotted thin grey' : 'solid 1px #0c8db4', borderRadius: '16px', cursor: numReviews < 1 ? 'default' : 'pointer', padding: window.innerWidth > 764 ? '16px':'4px' }} key={disability} >
     {/* {window.innerWidth>764?
        <Row style={{ padding: '8px', marginBottom: '0px', width: '100%' }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Label caption={disability ? disability.toUpperCase() : ''} labelType={window.innerWidth >= 768 ? 'H6' : 'H6'} style={{ padding: '4px', textAlign: 'center' }} />
          </Col>
        </Row>:null} */}
        <Row style={{ paddingBottom: '8px', marginBottom: '8px' }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Label caption={score !== '' ? score + '%' : '--'} labelType={window.innerWidth >= 768 ? 'H3' : 'H2'} style={{ padding: '4px', textAlign: 'center', minHeight: window.innerWidth >= 768 ? '0px' : '48px' }} block />
            <Label caption={numReviews > 0 ? numReviews + ' review' + (numReviews > 1 ? 's' : '') : 'No reviews'} labelType={'Body1'} style={{ padding: '4px', textAlign: 'center' }} />
            {/* {locationId !== '' & numReviews > 0 ? <Button type='link' onClick={() => this.showReport(locationId)} >View</Button> : null} */}
            {/* {locationId !=='' & numReviews > 0  ?<Button size={window.innerWidth >= 768 ? 'large' : 'default'} type='link' style={{ marginTop: window.innerWidth >= 768 ?'16px':'4px', paddingBottom: '24px' }} onClick={() => this.showReport(locationId)}>{window.innerWidth>=768?"See the full report":"more..."} </Button>:null} */}
          </Col>
        </Row>
       
      </div>
       {ReportWindow}
      </React.Fragment>
    )
  }

}
export default MapMobilityTab