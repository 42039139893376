import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import logosmall from '../Assets/am-logo.png';
import googlePlayStore from '../Assets/google-play-badge.png';
import appleStore from '../Assets/app-store-badge.svg';
import AboutUsIcon from '@material-ui/icons/Help';

import markerIconBlack from '../Assets/map-marker.png';
import Label from '../Label';
import AboutUs from '../Components/AboutUs';


export class MapHeader extends Component {
  state = {
    showWindow: false,
    windowTitle: '',
  }

  openWindow = (message) => {
    this.setState({ showWindow: true, windowTitle: message, });
  }

  handleCancel = () => {
    this.setState({ showWindow: false, windowTitle: '', });
  }

  handleShowAboutUs = () => {
    this.setState({ showAboutUs: true, });
  };

  handleCancelAboutUs = () => {
    this.setState({ showAboutUs: false, });
  };

  render() {
    const { showWindow, windowTitle, showAboutUs } = this.state;

    let ModalWindow = null;
    if (showWindow === true)
      ModalWindow = <Modal
        title={windowTitle}
        open={showWindow}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        width={600}
        footer={null}
      >
        <Row style={{ paddingBottom: '48px' }}>
          Questions about location
        </Row>

        <Row style={{ float: 'right', padding: '0px', marginTop: '8px', marginRight: '24px', marginBottom: '16px', position: 'absolute', bottom: '0px', right: '0px' }}>
          <Button size='large' type='primary' style={{ marginRight: '16px', float: 'right' }}>Save My Report</Button>
          <Button size='large' style={{ float: 'right' }} onClick={() => this.handleCancel()}>Cancel</Button>
        </Row>
      </Modal>;

    let AboutUsModal = null;
    if (showAboutUs === true)
      AboutUsModal = <Modal
        title={null}
        visible={showAboutUs}
        onCancel={this.handleCancelAboutUs}
        style={{ top: '56px', left: '0px', width: window.innerWidth, height: window.innerHeight - 56 }}
        footer={null}
      >
        <AboutUs />
        <a href="https://pos-abilities.org" target='_blank' rel="noreferrer" ><h4 style={{ color: '#0c8db4' }}>Go to our website</h4></a>
        <Row style={{ float: 'right', padding: '0px', marginTop: '8px', marginRight: '24px', marginBottom: '16px', position: 'absolute', bottom: '0px', right: '0px' }}>
          <Button type='default' size='large' style={{ float: 'right' }} onClick={() => this.handleCancelAboutUs()}>Close</Button>
        </Row>
      </Modal>;


    let page = null;

    if (window.innerWidth >= 768)
      page = <div id='mapHeader' style={{
        position: 'absolute',
        left: '0px',
        right: '0px',
        top: '0px'
      }}>
        <Row>
          <Col span={12}>
            <img src={logosmall} alt='An accessibility map for your community' style={{ maxHeight: window.innerWidth > 768 ? '144px':'100px', marginLeft: '24px', marginBottom: '8px' }} />
      
        </Col>
        <Col span={12}  >
          <div style ={{float:'right'}}>
              <AboutUsIcon style={{ float: 'right', margin: window.innerWidth < 768 ? '20px 20px' :'8px 16px', fontSize: '36px' }} onClick={() => this.handleShowAboutUs()} />
              <a href='https://play.google.com/store/apps/details?id=ca.accessibilitymap.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank' rel="noreferrer"><img src={googlePlayStore} alt='Get it on Google Play' style={{ maxHeight: window.innerWidth>768?'60px':'40px', marginLeft: '8px', marginTop: '8px' }} /></a>
              <a href='https://apps.apple.com/ca/app/accessibility-map/id6477662776?itsct=apps_box_promote_link&itscg=30200' target='_blank' rel="noreferrer"><img src={appleStore} alt='Get it on the App Store' style={{ maxHeight: window.innerWidth > 768 ? '48px' : '28px', marginLeft: '8px', marginTop: '8px' }} /></a>
            </div>
            <span style={{ position: 'absolute', bottom: window.innerWidth > 768 ? 24 : 8, right:  24, width: '100%', textAlign: 'right', display: window.innerWidth < 768 ? 'none' : 'block' }}>
       
            <img src={markerIconBlack} alt='Accessibility report' style={{ marginRight: '8px', maxHeight: '48px' }} />
            <Label caption={window.innerWidth > 1180 ? 'Accessibility Report Available' : 'Report Available'} labelType='H6' block={false} />
          </span>
        </Col>
      </Row>
      </div >;

    if (window.innerWidth < 768)
      page = <div id='mapHeader' style={{
        position: 'absolute',
        left: '0px',
        right: '0px',
        top: '0px'
      }}>
        <Row>
          <Col span={24}>
            <img src={logosmall} alt='Access for All' style={{ maxHeight: '140px' }} />
          </Col>
          <Col span={24} style={{ textAlign: 'center', padding: '0px 16px 16px 16px' }}>
          </Col>
        </Row>
      </div>;

    return (
      <React.Fragment>
        {page}
        {ModalWindow}
        {AboutUsModal}
      </React.Fragment>
    )
  }
}
export default MapHeader