import React, { Component } from 'react';
import { Modal, Button, Row, Col, Input, Tooltip, notification, Spin, Tabs } from 'antd';
import { GoogleMap, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import { CompassFilled } from '@ant-design/icons';
import axios from 'axios';
import Label from '../Label';
import MapMobilityTab from './MapMobilityTab';
import LocationReport from './LocationReport';
import markerIconBlack from '../Assets/map-marker.png';
import accessVerified from '../Assets/accessVerified.png';
import parkingBrowser from '../Assets/Parking_48x48_2.png';
import signageBrowser from '../Assets/Signage_48x48_2.png';
import entryBrowser from '../Assets/Entry_48x48_2.png';
import restroomBrowser from '../Assets/Restrooms_48x48_2.png';
import insideBrowser from '../Assets/Inside_48x48_2.png';
import elevatorBrowser from '../Assets/Elevator_48x48_2.png';
import seatingBrowser from '../Assets/Seating_48x48_2.png';
import gymBrowser from '../Assets/Gym_48x48_2.png';
import poolBrowser from '../Assets/Pool_48x48_2.png';
import theatreBrowser from '../Assets/Theatre_48x48_2.png';
import lodgingBrowser from '../Assets/Lodging_48x48_2.png';
import gasbarBrowser from '../Assets/GasBar_48x48_2.png';
import ReactGA from 'react-ga';

const modalStyle = {
  top: 56
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  scaleControl: false,
  fullscreenControl: false
};
const { Search } = Input;
const { TabPane } = Tabs;

export class WebMap extends Component {
  state = {
    search: '',
    center: {
      lat: 49.1067702,
      lng: -122.7346456
    },
    showLocationModal: false,
    showReport: false,
    reportType: '',
    locationId: '',
    placeId: '',
    locationName: '',
    currentLocationId: '',
    POI: null,
    mapData: [],
    steps: [],
    placeTypes: [],
    bounds: null,
    currentLocation: {},
    spinning: false,
    autoComplete: null,
  }

  componentDidMount() {
    this.loadMapData();
    this.loadPlaceTypes();
    setTimeout(() => {
      this.getUserPosition()
    }, 1000);

  }

  getUserPosition = () => {
    const map = this.map;
    if (map) {
      let center = this.state.center;
      navigator.geolocation.getCurrentPosition((position) => {
        center = { lat: position.coords.latitude, lng: position.coords.longitude };
        this.setState({ center: center, });
        map.panTo({ lat: position.coords.latitude, lng: position.coords.longitude });
        map.setZoom(11);
      }, () => null, options);
    }
  }

  loadMapData = () => {
    this.setState({ spinning: true, });
    axios.get(`https://webmap.azurewebsites.net/api/map/locations`)
      .then(res => {
        this.setState({ mapData: res.data["mapLocationModels"], spinning: false, });
      }).catch(err => {
        this.setState({ spinning: false, });
        console.log(err)
      });
  }

  loadPlaceTypes = () => {
    axios.get(`https://webmap.azurewebsites.net/api/report/placeTypes`)
      .then(res => {
        this.setState({ placeTypes: res.data["placeTypes"], });
      }).catch(err => {
        console.log(err)
      });
  }

  getGoogleData = async placeId => new Promise((resolve, reject) => {
    let google = this.props.google;
    const service = new google.maps.places.PlacesService(this.map);
    let request = { placeId: placeId };
    service.getDetails(request, (results, status) => {
      return resolve(results)
    });
  });

  openLocationModal = async (locationId, placeId, latLongGroup, googleData, hasBusinessReport) => {
    const { mapData } = this.state;
    let currentSummary = {};
    let currentFeatures = {};
    let currentScores = {};

    ReactGA.event({ category: 'Button', action: 'Click', label: 'View Location', });

    //if there is no google data, load the data into mapSummary to get the latest info
    if (googleData === null) {
      let results = await this.getGoogleData(placeId);
      currentSummary = this.formatLocationData(results);
      currentSummary['hasBusinessReport'] = hasBusinessReport;
    }
    else {
      currentSummary = this.formatLocationData(googleData);
    }

    //get marker data if it exists for this placeId
    let mapSummary = mapData.filter(x => x.placeId === placeId);

    if (mapSummary.length > 0) {
      mapSummary = mapSummary[0];
      locationId = mapSummary.locationId;
      latLongGroup = mapSummary.latLongGroup;

      axios.get(`https://webmap.azurewebsites.net/api/map/location/` + locationId + `/` + latLongGroup)
        .then(res => {
          currentSummary['locationId'] = locationId;
          currentSummary['placeId'] = placeId;
          currentSummary['latLongGroup'] = latLongGroup;
          currentSummary['reportVerified'] = res.data["locationModel"].reportVerified;
          currentSummary['locationName'] = res.data["locationModel"].locationName;
          currentSummary['hasBusinessReport'] = res.data["locationModel"].hasBusinessReport;
          currentSummary['verified'] = res.data["locationModel"].verified;

          this.setState({
            locationId: locationId,
            locationName: res.data["locationModel"].locationName,
            mapSummary: currentSummary,
            mapFeatures: res.data["locationFeaturesModel"] ? res.data["locationFeaturesModel"] : {},
            mapScores: res.data["locationScoreModel"] ? res.data["locationScoreModel"] : { locationId: '', mobilityRating: '', mobilityReviews: 0, visionRating: '', visionReviews: 0, hearingRating: '', hearingReviews: 0 },
          }, () => this.setState({ showLocationModal: true, }));

        }).catch(err => {
          console.log(err)
        });

    }
    else {
      currentSummary['reportVerified'] = false;
      currentSummary['hasBusinessReport'] = false;
      currentFeatures = { parking: false, restrooms: false };
      currentScores = { locationId: '', mobilityRating: '', mobilityReviews: 0, visionRating: '', visionReviews: 0, hearingRating: '', hearingReviews: 0 };
      this.setState({ locationId: locationId, locationName: currentSummary.locationName, mapSummary: currentSummary, mapFeatures: currentFeatures, mapScores: currentScores, }, () => this.setState({ showLocationModal: true, }));
    }
  };

  formatLocationData(googleData) {
    let placeId = googleData.place_id;
    let locationName = googleData.name;
    let category = googleData.types;
    let phoneNumber = googleData.formatted_phone_number;
    let googleURL = googleData.url;
    let website = googleData.website;
    let address = googleData.address_components;
    let lat = googleData.geometry.location.lat();
    let lng = googleData.geometry.location.lng();
    let streetAddress = '';
    let city = '';
    let region = '';
    let postalCode = '';
    let locationData = {};

    address.forEach((addSection) => {
      switch (addSection.types[0]) {
        case 'subpremise':
          streetAddress = addSection.long_name;
          break;
        case 'street_number':
          if (streetAddress === '')
            streetAddress = addSection.long_name;
          else
            streetAddress = streetAddress + ' - ' + addSection.long_name;
          break;
        case 'route':
          streetAddress = streetAddress + ' ' + addSection.long_name;
          break;
        case 'locality':
          city = addSection.long_name;
          break;
        case 'administrative_area_level_1':
          region = addSection.short_name;
          break;
        case 'postal_code':
          postalCode = addSection.long_name;
          break;
        default:
      }
    });

    locationData = { locationId: '', placeId: placeId, locationName: locationName, streetAddress: streetAddress, city: city, region: region, postalCode: postalCode, category: category, phoneNumber: phoneNumber, googleURL: googleURL, website: website, lat: lat, lng: lng };
    return locationData;
  }

  closeLocationModal = () => {
    this.setState({ showLocationModal: false, });
  }

  showReport = (locationId, placeId, reportType) => {
    this.setState({ showReport: true, locationId: locationId, placeId: placeId, reportType: reportType, },);
  };

  closeReport = (reportsSubmitted, refreshMap, score) => {
    this.setState({ showReport: false, showLocationModal: false, });

    if (refreshMap)
      this.loadMapData();

    if (reportsSubmitted === true) {
      let description = 'Thank you for submitting an Accessibility Report for this location!' + String.fromCharCode(13);
      if (score > 0) { description = description + 'You scored this location at ' + score.toFixed(0).toString() + '%!'; }

      notification.open({ message: 'Report Submitted', description: description, placement: window.innerWidth < 768 ? 'bottomRight' : 'topRight', duration: null });
    }
  };

  onTabClick = (key) => {
    this.setState({ currentTab: key, });
  };

  onPlacesChanged = () => {
    let currentLocation = this.searchBox.getPlaces()[0];
    let locationId = '';
    let placeId = currentLocation.place_id;
    let lat = currentLocation.geometry.location.lat();
    let lng = currentLocation.geometry.location.lng();
    let latLongGroup = lat.toFixed(1).toString() + ',' + lng.toFixed(1).toString();

    this.map.panTo({ lat, lng });
    this.map.setZoom(11);
    this.setState({ search: currentLocation.name, });

    if (currentLocation.address_components && currentLocation.types[0] !== 'locality' && currentLocation.types[0] !== 'neighborhood' && currentLocation.types[0] !== 'political' && currentLocation.types[0] !== 'natural_feature')
      this.openLocationModal(locationId, placeId, latLongGroup, currentLocation, false);
  };

  onBoundsChanged = () => {
    let bounds = this.map.getBounds();
    this.setState({ bounds: bounds, })
  };

  isIconMouseEvent = (e) => {
    return "placeId" in e;
  };

  onMapClick = (event) => {
    if (this.isIconMouseEvent(event)) {
      event.stop();
      let google = this.props.google;
      const service = new google.maps.places.PlacesService(this.map);
      let placeId = event.placeId;
      let request = { placeId: event.placeId };

      service.getDetails(request, (results, status) => {
        //Calculate Lat, Lng Group
        let latLongGroup = '-123.4,23.2';
        this.openLocationModal('', placeId, latLongGroup, results);
      });
    }
  };

  onUnMount = () => {

  }

  clearSearch = () => {
    this.setState({ search: '', })
  };

  changeSearch = (searchString) => {
    this.setState({ search: searchString, })
  };

  getPlaceTypeName = (placeType) => {
    const { placeTypes } = this.state;
    let searchTypes = placeTypes;
    searchTypes = searchTypes.filter(x => x.placeType === placeType);
    if (searchTypes.length > 0) {
      return (searchTypes[0].placeTypeName);
    }
    else { return null; }
  }

  handleInputChange = (e) => {
    const value = e.target.value;
    this.setState({ search: value });
  };

  render() {
    const { spinning, mapFeatures, search, bounds, reportType, placeTypes, placeId, locationId, locationName, mapScores, showLocationModal, showReport, center, mapData, mapSummary } = this.state;
    const image = {
      url: markerIconBlack,
      anchor: new this.props.google.maps.Point(2, 18),
    };

    const onLoadMap = ref => this.map = ref;
    const onLoad = ref => this.searchBox = ref;


    let heightOffset = window.innerWidth < 786 ? 56 : 188;

    let containerStyle = {
      width: window.innerWidth,
      height: window.innerHeight - heightOffset
    };

    let reportTitle = <h3 style={{ marginTop: '4px', width: '95%', color: 'white' }}>{mapSummary ? mapSummary.locationName : ''}</h3>;

    let LocationWindow = null;
    if (showLocationModal === true && window.innerWidth >= 768)
      LocationWindow = <Modal
        title={reportTitle}
        visible={showLocationModal}
        onOk={this.handleOk}
        onCancel={() => this.closeLocationModal()}
        width={600}
        footer={null}
        maskClosable={false}
        altText={'Location details for ' + mapSummary.locationName}
        bodyStyle={{ margin: '0px', padding: '16px' }}
        centered
      >
        <Row style={{ paddingBottom: '4px' }}>
          <Col span={16}>
            {mapSummary.category ? mapSummary.category.map(placeType => {
              if (this.getPlaceTypeName(placeType))
                return (<Label labelType='Tag' style={{ color: 'white', backgroundColor: 'black', padding: '4px 8px', marginRight: '8px', borderRadius: '4px', marginBottom: '8px' }} key={placeType} caption={this.getPlaceTypeName(placeType)} />)
              else
                return null
            }) : null}

            <Label caption={mapSummary.streetAddress} labelType='Body2' block />
            <Label caption={mapSummary.city + ',' + mapSummary.region + ' ' + mapSummary.postalCode} labelType='Body2' block />
            <a href={'tel:' + mapSummary.phoneNumber}><Label caption={mapSummary.phoneNumber} labelType='Body1' /></a>
            <br /> <br />
            <a href={mapSummary.googleURL} target='_blank' rel="noreferrer"><Label caption='Find on Google Maps' labelType='Body2' style={{ fontWeight: 500, height: '48px' }} /></a>

          </Col>
          <Col span={8}>
            <Tooltip title='Accessibility Report Verified'>
              <img src={accessVerified} alt='Accessibility Information is verified' style={{ marginTop: '0px', marginRight: '8px', width: '90%', float: 'right', display: mapSummary.verified === true ? 'block' : 'none' }} />
            </Tooltip>   {/* <a href={mapSummary.googleURL} target='_blank' rel="noreferrer"><Label caption='Find on Google Maps' labelType='Body2' style={{ position: 'absolute', right: 24, top: '100px', fontWeight: 500 }} /></a> */}
          </Col>
        </Row>
        {locationId !== '' ?
          <React.Fragment>
            <Row style={{ marginBottom: '8px', marginTop: '0px' }}>
              <Label caption='Accessibility Features' labelType='H6' />
            </Row>
            <Row style={{ paddingBottom: '16px', paddingTop: '0px' }}>
              {mapFeatures.parking === true ? <Tooltip title='Has Parking'><img src={parkingBrowser} alt='Has Wheelchair Accessible Parking' style={{ marginRight: '8px', maxHeight: '80px', paddingBottom: '4px' }} /></Tooltip> : null}
              {mapFeatures.signage === true ? <Tooltip title='Has Signage'><img src={signageBrowser} alt='Has Signage' style={{ marginRight: '8px', maxHeight: '80px', paddingBottom: '4px' }} /> </Tooltip> : null}
              {mapFeatures.entry === true ? <Tooltip title='Has An Entrance'><img src={entryBrowser} alt='Has Entrance' style={{ marginRight: '8px', maxHeight: '80px', paddingBottom: '4px' }} /></Tooltip> : null}
              {mapFeatures.restrooms === true ? <Tooltip title='Has Restrooms'><img src={restroomBrowser} alt='Has Wheelchair Accessible Restrooms' style={{ marginRight: '8px', maxHeight: '80px', paddingBottom: '4px' }} /></Tooltip> : null}
              {mapFeatures.inside === true ? <Tooltip title='Has Inside Area'><img src={insideBrowser} alt='Has Inside Area' style={{ marginRight: '8px', maxHeight: '80px', paddingBottom: '4px' }} /></Tooltip> : null}
              {mapFeatures.elevator === true ? <Tooltip title='Has Elevator'><img src={elevatorBrowser} alt='Has Elevator' style={{ marginRight: '8px', maxHeight: '80px', paddingBottom: '4px' }} /></Tooltip> : null}
              {mapFeatures.seating === true ? <Tooltip title='Has Seating'><img src={seatingBrowser} alt='Has Seating' style={{ marginRight: '8px', maxHeight: '80px', paddingBottom: '4px' }} /> </Tooltip> : null}
              {mapFeatures.gym === true ? <Tooltip title='Has Gym'><img src={gymBrowser} alt='Has Gym' style={{ marginRight: '8px', maxHeight: '80px', paddingBottom: '4px' }} /> </Tooltip> : null}
              {mapFeatures.pool === true ? <Tooltip title='Has Pool'><img src={poolBrowser} alt='Has Pool' style={{ marginRight: '8px', maxHeight: '80px', paddingBottom: '4px' }} /> </Tooltip> : null}
              {mapFeatures.theatre === true ? <Tooltip title='Has Theatre'><img src={theatreBrowser} alt='Has Theatre' style={{ marginRight: '8px', maxHeight: '80px', paddingBottom: '4px' }} /></Tooltip> : null}
              {mapFeatures.lodging === true ? <Tooltip title='Has Lodging'><img src={lodgingBrowser} alt='Has lodging' style={{ marginRight: '8px', maxHeight: '80px', paddingBottom: '4px' }} /> </Tooltip> : null}
              {mapFeatures.gasbar === true ? <Tooltip title='Has Gas Bar'><img src={gasbarBrowser} alt='Has Gas Bar' style={{ marginRight: '8px', maxHeight: '80px', paddingBottom: '4px' }} /> </Tooltip> : null}
            </Row>

            <Row style={{ marginBottom: '8px' }}>
              <Label caption='Disability Friendliness Score' labelType='H6' />
            </Row>
            <Tabs defaultActiveKey="1" style={{ width: '80%', textAlign: 'center' }} size='large' tabPosition='left'  >
              <TabPane tab="MOBILITY" key="1" >
                <MapMobilityTab locationId={locationId} locationName={locationName} mapScores={mapScores} reportType={reportType} disability='Mobility' />
              </TabPane>
              <TabPane tab="VISION" key="2">
                <MapMobilityTab locationId={locationId} locationName={locationName} mapScores={mapScores} reportType={reportType} disability='Vision' />
              </TabPane>
              <TabPane tab="HEARING" key="3">
                <MapMobilityTab locationId={locationId} locationName={locationName} mapScores={mapScores} reportType={reportType} disability='Hearing' />
              </TabPane>
            </Tabs>
            {/* <Row style={{ paddingBottom: '16px' }}>
              <Col span={8} >
                <MapMobilityTab locationId={locationId} locationName={locationName} mapScores={mapScores} reportType={reportType} disability='Mobility' />
              </Col>
              <Col span={8} >
                <MapMobilityTab locationId={locationId} locationName={locationName} mapScores={mapScores} reportType={reportType} disability='Vision' />
              </Col>
              <Col span={8} >
                <MapMobilityTab locationId={locationId} locationName={locationName} mapScores={mapScores} reportType={reportType} disability='Hearing' />
              </Col>
            </Row> */}
          </React.Fragment> :
          <div style={{ minHeight: '200px', margin: '16px 0px', padding: '16px', textAlign: 'left' }}>
            <Label caption='No User Reviews Available' labelType='H5' block style={{ marginBottom: '16px', textAlign: 'center' }} />
            <Label caption='Be the first to submit a review for this location!' labelType='H6' block style={{ marginBottom: '16px', textAlign: 'center' }} />
          </div>
        }
        <Row >
          <Col span={24}>
            {mapSummary.hasBusinessReport === false && mapSummary.region === 'BC' ? <Button size='large' type='link' style={{ marginBottom: '16px' }} onClick={() => this.showReport(locationId, placeId, 'business')}>I operate this location and would like to submit a Location Report...</Button> : null}
            <Button size='large' type='primary' onClick={() => this.showReport(locationId, placeId, 'user')}>Start My Review</Button>
            <Button size='large' type='default' style={{ float: 'right', width: '92px' }} onClick={() => this.closeLocationModal()}>Close</Button>
          </Col>
        </Row>
      </Modal>;


    if (showLocationModal === true && window.innerWidth < 768)
      LocationWindow = <Modal
        title={reportTitle}
        style={modalStyle}
        visible={showLocationModal}
        onCancel={this.closeLocationModal}
        width={768}
        footer={null}
        maskClosable={false}
      >
        <Row style={{ paddingBottom: '8px', marginBottom: '0px' }}>
          <Col span={20}>
            {/* {mapSummary.category ? mapSummary.category.map(placeType => {
              if (this.getPlaceTypeName(placeType))
                return (<Label labelType='Tag' style={{ color: 'white', backgroundColor: 'black', padding: '4px 8px', marginRight: '8px', borderRadius: '4px', marginBottom: '8px' }} key={placeType} caption={this.getPlaceTypeName(placeType)} />)
              else
                return null
            }) : null} */}

            <Label caption={mapSummary.streetAddress} labelType='Body2' block />
            <Label caption={mapSummary.city + ',' + mapSummary.region + ' ' + mapSummary.postalCode} labelType='Body2' block />
            {mapSummary.phoneNumber !== '' ? <a href={'tel:' + mapSummary.phoneNumber} ><Label caption={mapSummary.phoneNumber} labelType='Body2' style={{ fontWeight: 500, height: '48px', width:'100%' }} /></a> : null}
            <a href={mapSummary.googleURL} target='_blank' rel="noreferrer"><Label caption='Find on Google Maps' labelType='Body2' style={{ fontWeight: 500, height: '48px' }} /></a>

          </Col>
          <Col span={4}>
            <Tooltip title='Accessibility Report Verified'>
              <img src={accessVerified} alt='Accessibility Information is verified' style={{ marginTop: '0px', marginRight: '0px', float: 'right', width: '100%', display: mapSummary.verified === true ? 'block' : 'none' }} />
            </Tooltip>
          </Col>
        </Row>
        {locationId !== '' ?
          <React.Fragment>
            <Row style={{ marginBottom: '8px' }}>
              <Label caption='Accessibility Features' labelType='H6' />
            </Row>
            <Row style={{ paddingBottom: '16px', paddingTop: '0px' }}>
              {mapFeatures.parking === true ? <Tooltip title='Has Parking'><img src={parkingBrowser} alt='Has Wheelchair Accessible Parking' style={{ marginRight: '8px', maxHeight: '48px', marginBottom: '4px' }} /></Tooltip> : null}
              {mapFeatures.signage === true ? <Tooltip title='Has Signage'><img src={signageBrowser} alt='Has Signage' style={{ marginRight: '8px', maxHeight: '48px', marginBottom: '4px' }} /> </Tooltip> : null}
              {mapFeatures.entry === true ? <Tooltip title='Has An Entrance'><img src={entryBrowser} alt='Has Entrance' style={{ marginRight: '8px', maxHeight: '48px', marginBottom: '4px' }} /></Tooltip> : null}
              {mapFeatures.restrooms === true ? <Tooltip title='Has Restrooms'><img src={restroomBrowser} alt='Has Wheelchair Accessible Restrooms' style={{ marginRight: '8px', maxHeight: '48px', marginBottom: '4px' }} /></Tooltip> : null}
              {mapFeatures.inside === true ? <Tooltip title='Has Inside Area'><img src={insideBrowser} alt='Has Inside Area' style={{ marginRight: '8px', maxHeight: '48px', marginBottom: '4px' }} /></Tooltip> : null}
              {mapFeatures.elevator === true ? <Tooltip title='Has Elevator'><img src={elevatorBrowser} alt='Has Elevator' style={{ marginRight: '8px', maxHeight: '48px', marginBottom: '4px' }} /></Tooltip> : null}
              {mapFeatures.seating === true ? <Tooltip title='Has Seating'><img src={seatingBrowser} alt='Has Seating' style={{ marginRight: '8px', maxHeight: '48px', marginBottom: '4px' }} /> </Tooltip> : null}
              {mapFeatures.gym === true ? <Tooltip title='Has Gym'><img src={gymBrowser} alt='Has Gym' style={{ marginRight: '8px', maxHeight: '48px', marginBottom: '4px' }} /> </Tooltip> : null}
              {mapFeatures.pool === true ? <Tooltip title='Has Pool'><img src={poolBrowser} alt='Has Pool' style={{ marginRight: '8px', maxHeight: '48px', marginBottom: '4px' }} /> </Tooltip> : null}
              {mapFeatures.theatre === true ? <Tooltip title='Has Theatre'><img src={theatreBrowser} alt='Has Theatre' style={{ marginRight: '8px', maxHeight: '48px', marginBottom: '4px' }} /></Tooltip> : null}
              {mapFeatures.lodging === true ? <Tooltip title='Has Lodging'><img src={lodgingBrowser} alt='Has lodging' style={{ marginRight: '8px', maxHeight: '48px', marginBottom: '4px' }} /> </Tooltip> : null}
              {mapFeatures.gasbar === true ? <Tooltip title='Has Gas Bar'><img src={gasbarBrowser} alt='Has Gas Bar' style={{ marginRight: '8px', maxHeight: '48px', marginBottom: '4px' }} /> </Tooltip> : null}

            </Row>

            <Row style={{ marginBottom: '8px' }}>
              <Label caption='Disability Friendliness Score' labelType='H6' />
            </Row>
            <Row>
              <Tabs defaultActiveKey="1" style={{ width: '100%' }} size='large' tabPosition='left' >
                <TabPane tab="MOBILITY" key="1" style={{ height: '48px' }}>
                  <MapMobilityTab locationId={locationId} locationName={locationName} mapScores={mapScores} reportType={reportType} disability='Mobility' />
                </TabPane>
                <TabPane tab="VISION" key="2" style={{ height: '48px' }}>
                  <MapMobilityTab locationId={locationId} locationName={locationName} mapScores={mapScores} reportType={reportType} disability='Vision' />
                </TabPane>
                <TabPane tab="HEARING" key="3" style={{ height: '48px' }}>
                  <MapMobilityTab locationId={locationId} locationName={locationName} mapScores={mapScores} reportType={reportType} disability='Hearing' />
                </TabPane>
              </Tabs>
              {/* <Col span={24}>
                <MapMobilityTab locationId={locationId} locationName={locationName} mapScores={mapScores} reportType={reportType} disability='Mobility' />
              </Col> */}
              {/* <Col span={8}>
                <MapMobilityTab locationId={locationId} locationName={locationName} mapScores={mapScores} reportType={reportType} disability='Vision' />
              </Col>
              <Col span={8}>
                <MapMobilityTab locationId={locationId} locationName={locationName} mapScores={mapScores} reportType={reportType} disability='Hearing' />
              </Col> */}
            </Row>

          </React.Fragment>
          :
          <div style={{ margin: '0px', padding: '8px', textAlign: 'left', border: 'thin solid grey' }}>
            <Label caption='No User Reviews Available' labelType='H6' block style={{ marginBottom: '16px', textAlign: 'center' }} />
            <Label caption='Be the first to submit a review for this location!' labelType='Body1' block style={{ marginBottom: '16px', textAlign: 'center' }} />
          </div>
        }

        <Row style={{ paddingTop: '16px' }}>
          <Col span={24}>
            {mapSummary.hasBusinessReport === false && mapSummary.region === 'BC' ? <Button size='large' type='link' style={{ marginBottom: '16px' }} onClick={() => this.showReport(locationId, placeId, 'business')}>Self-report for this Location</Button> : null}
            <Button size='large' type='primary' onClick={() => this.showReport(locationId, placeId, 'user')}>Start My Review</Button>
            <Button size='large' type='default' style={{ float: 'right', width: '92px' }} onClick={() => this.closeLocationModal()}>Close</Button>
          </Col>
        </Row>
      </Modal>;

    let ReportWindow = null;
    if (showReport === true)
      ReportWindow = <Modal
        title={<Label caption='Accessibility Report' labelType='H6' style={{ color: 'white' }} />}
        visible={showReport}
        onCancel={this.closeReport}
        width={768}
        footer={null}
        maskClosable={false}
        zIndex={9999999}
        style={{ top: 56 }}
      >
        <LocationReport closeReport={this.closeReport} locationId={locationId} placeId={placeId} reportType={reportType} locationName={locationName} mapSummary={mapSummary} placeTypes={placeTypes} />
      </Modal>;

    return (
      <Spin spinning={spinning} tip='Loading accessibility information...'>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={window.innerWidth >= 768 ? 11 : 12}
          region='CA'
          language='en'
          gestureHandling='cooperative'
          options={options}
          mapTypeControl={false}
          mapTypeId='roadmap'
          onLoad={onLoadMap}
          onUnMount={this.onUnMount}
          onClick={(event) => this.onMapClick(event)}
          onBoundsChanged={() => this.onBoundsChanged()}
        >
          <Tooltip title='Move map to your current location' placement='bottomLeft' altText='Move map to your current location'>
            {window.innerWidth >= 768 ?
              <CompassFilled aria-label='Click to move map to your current location' style={{
                position: "absolute",
                right: 0,
                marginTop: window.innerWidth >= 768 ? '16px' : '64px',
                marginRight: '16px',
                fontSize: '40px',
                height: '48px',
                width: '48px',
                color: 'brown',
                cursor: 'pointer',
                altText: 'Move map to your current location',
                tabIndex: 0
              }}
                onClick={() => this.getUserPosition()}
              /> :
              <CompassFilled aria-label='Click to move map to your current location' style={{
                position: "absolute",
                right: 16,
                marginTop: '80px',
                marginRight: '0px',
                fontSize: '40px',
                height: '48px',
                width: '48px',
                color: 'brown',
                cursor: 'pointer',
                altText: 'Move map to your current location',
                tabIndex: 0
              }}
                onClick={() => this.getUserPosition()}
              />}
          </Tooltip>


          {window.innerWidth >= 768 ?
            <StandaloneSearchBox
              onLoad={onLoad}
              onPlacesChanged={() => this.onPlacesChanged()}
              bounds={bounds}>
              <div id='searchTitle' >
                <Search
                  placeholder="Search by location or address"
                  enterButton={<Tooltip title="Clear your location search" placement='bottomLeft'><Label caption='Clear' aria-label='Click to clear your location search' /></Tooltip>}
                  aria-label='Location search'
                  size="large"
                  tabIndex={0}
                  value={search}
                  onSearch={() => this.clearSearch()}
                  onChange={this.handleInputChange}
                  //                onChange={(e) => this.changeSearch(e.search)}
                  autoComplete={{ componentRestrictions: { 'country': ['ca'] } }}
                  style={{
                    position: "absolute",
                    right: 0,
                    marginTop: '16px',
                    marginRight: window.innerWidth >= 768 ? '72px' : '16px',
                    width: window.innerWidth >= 768 ? '420px' : window.innerWidth - 48
                  }} />
              </div>
            </StandaloneSearchBox> :
            <StandaloneSearchBox
              onLoad={onLoad}
              onPlacesChanged={() => this.onPlacesChanged()}
              bounds={bounds}
              style={{ height: '48px' }}
            >
              <div id='searchTitle' >
                <Search
                  placeholder="Enter location, address, or click map"
                  enterButton={<Tooltip title="Clear your location search" placement='bottomLeft'><Label caption='Clear' aria-label='Click to clear your location search' /></Tooltip>}
                  aria-label='Location search'
                  size="large"
                  tabIndex={0}
                  value={search}
                  onSearch={() => this.clearSearch()}
                  onChange={this.handleInputChange}
                  autoComplete={{ componentRestrictions: { 'country': ['ca'] } }}
                  style={{
                    position: "absolute",
                    left: 16,
                    marginTop: '16px',
                    marginRight: '16px',
                    height: '48px',
                    color: 'black',
                    width: window.innerWidth - 32
                  }} />
              </div>
            </StandaloneSearchBox>}
          {mapData.map(location => {
            return (
              <Marker
                key={location.placeId}
                position={{ lat: location.latitude, lng: location.longitude }}
                icon={image}
                title={location.locationName}
                altText={location.locationName}
                aria-label={location.locationName}
                onClick={() => this.openLocationModal(location.locationId, location.placeId, location.latLongGroup, null, location.hasBusinessReport)}
              />
            )
          })
          }

        </GoogleMap>

        {LocationWindow}
        {ReportWindow}
      </Spin>
    )
  };
}

export default WebMap
