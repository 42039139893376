import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import TermsIcon from '@material-ui/icons/CheckBox';
import AboutUsIcon from '@material-ui/icons/Help';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Label from '../Label';
import { Row, Modal, Button } from 'antd';
import companyLogo from '../Assets/am-logo.png';
import logoIcon from '../Assets/logo-icon-mobile.jpg';
import Terms from '../Components/Terms';
import AboutUs from '../Components/AboutUs';
import googlePlayStore from '../Assets/google-play-badge.png';
import appleStore from '../Assets/app-store-badge.svg';

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 250,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        //marginRight: theme.spacing(2), color: "white"
    },
    title: {
        flexGrow: 1,
        color: "white",
        textAlign: 'right',
    },
    mobiletitle: {
        flexGrow: 1,
        color: "white",
        textAlign: 'left',
    },
    titleName: {
        flexGrow: 1,
        color: "white",
        textAlign: 'left',
    },
    toolbar: {
        backgroundColor: "black",
        opacity: '1',
        margin: 0
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    list: {
        width: 250,

    },
    fullList: {
        width: 'auto',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    logo: {
        marginRight: theme.spacing(2),
        // Add any additional styling for your logo

    }
}));

export default function MobileTopBar(props) {

    const classes = useStyles();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
        showTerms: false,
        showAboutUs: false
    });

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [side]: open });
    };

    const handleMenuClick = (menuOption) => {
        switch (menuOption) {
            case 'about':
                setState({ ...state, right: false, showAboutUs: true });
                break;
            case 'terms':
                setState({ ...state, right: false });
                window.location.href = 'https://pos-abilities.org/app-privacy'; 
                break;
            default:
        }
    };

    const handleCancelTerms = () => {
        setState({ ...state, showTerms: false });
    };

    const handleCancelAboutUs = () => {
        setState({ ...state, showAboutUs: false });
    };

    let TermsModal = null;
    if (state.showTerms === true)
        TermsModal = <Modal
            title={null}
            visible={state.showTerms}
            onCancel={handleCancelTerms}
            style={{ top: '56px', width: '100%' }}
            footer={null}
        >
            <Terms />
            <Row style={{ float: 'right', padding: '0px', marginTop: '8px', marginRight: '24px', marginBottom: '16px', position: 'absolute', bottom: '0px', right: '0px' }}>
                <Button type='default' size='large' style={{ float: 'right' }} onClick={() => handleCancelTerms()}>Close</Button>
            </Row>
        </Modal>;

    let AboutUsModal = null;
    if (state.showAboutUs === true)
        AboutUsModal = <Modal
            title={null}
            visible={state.showAboutUs}
            onCancel={handleCancelAboutUs}
            style={{ top: '56px', left: '0px', width: window.innerWidth, height: window.innerHeight - 56 }}
            footer={null}
        >
            <AboutUs />
            <a href="https://pos-abilities.org" target='_blank' rel="noreferrer" ><h4 style={{ color:'#0c8db4' }}>Go to our website</h4></a>
            <Row style={{ float: 'right', padding: '0px', marginTop: '8px', marginRight: '24px', marginBottom: '16px', position: 'absolute', bottom: '0px', right: '0px' }}>
                <Button type='default' size='large' style={{ float: 'right' }} onClick={() => handleCancelAboutUs()}>Close</Button>
            </Row>
        </Modal>;

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
        >
         
                <img src={companyLogo} alt='Langley Pos-Abilities Society' style={{ width: '100%', height: 'auto', paddingBottom: '8px' }} />
            <Divider />
            <Row >
                <a href='https://apps.apple.com/ca/app/accessibility-map/id6477662776?itsct=apps_box_promote_link&itscg=30200' target='_blank' rel="noreferrer"><img src={appleStore} alt='Get it on the App Store' style={{ width:'218px', marginTop: '4px', marginLeft:'16px' }} /></a>
                <a href='https://play.google.com/store/apps/details?id=ca.accessibilitymap.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank' rel="noreferrer"><img src={googlePlayStore} alt='Get it on Google Play' style={{ width: '100%', marginTop: '8px' }} /></a>

            </Row>
            <Divider />
            <List>
                <ListItem button key="about" onClick={() => handleMenuClick("about")} >
                    <ListItemIcon><AboutUsIcon />
                    </ListItemIcon>
                    <ListItemText primary="About Us" style={{ color: 'black', fontWeight: 600 }} />
                </ListItem>

                <ListItem button key="terms" onClick={() => handleMenuClick("terms")} >
                    <ListItemIcon><TermsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Terms" style={{ color: 'black', fontWeight: 600 }} />
                </ListItem>

            </List>
           
            <Row >
                <Label type='Body2' caption={'©' + (new Date().getFullYear()) + ' Langley Pos-Abilities Society'} style={{ padding: '8px 16px', fontWeight: 'bold', color: 'black' }} />
            </Row>
        </div>
    );

    const renderMenu = (
        <Drawer open={state.right} anchor='right' onClose={toggleDrawer('right', false)} style={{ zIndex: 99999 }}>
            {sideList('right')}
        </Drawer>
    );

    const renderToolbar = (
        <Toolbar className={classes.toolbar} >
            <img src={logoIcon} alt='Accessibility Map' style={{ height: '48px' }} className={classes.logo} />
            <Typography className={classes.titleName} variant="h6" noWrap>
                Accessibility Map
            </Typography>
            <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer('right', true)}
            >
                <MenuIcon />
            </IconButton>
        </Toolbar>
    );
    return (
        <div>
            <AppBar position='fixed' >
                {renderToolbar}
            </AppBar>
            {renderMenu}
            {TermsModal}
            {AboutUsModal}
        </div>
    );
}


